<template>
  <div class="userListAdd-container">
    <a-modal class="userList-drawer" width="560px" okText="确认" cancelText="取消" :title="tableRow ? '修改员工' : '新增员工'" :visible="drawer" :confirm-loading="confirmLoading" @ok="() => { submitForm('ruleFormUser') }" @cancel="handleClose">
      <div class="drawer-container">
        <el-form
          :model="ruleFormAdd"
          :rules="rulesAdd"
          ref="ruleFormUser"
          label-width="140px"
        >
          <el-form-item label="员工名称" prop="nickName">
            <el-input
              class="common-screen-input_100"
              placeholder="请输入名称"
              v-model="ruleFormAdd.nickName"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="选择公司" prop="companyId">
            <el-select
              clearable
              filterable
              @change="companyFun"
              class="common-screen-input_100"
              v-model="ruleFormAdd.companyId"
              placeholder="请选择公司"
            >
            <span v-for="item in rolesCompany" :key="item.id">
              <el-option :key="item.id" :label="item.name" :value="item.id" v-if="item.id != 0"></el-option>
            </span>
            </el-select>
          </el-form-item> -->
          <el-form-item label="选择部门">
            <el-cascader
              clearable
              :props="{
                label: 'name',
                children: 'child',
                value: 'id',
                emitPath: false,
              }"
              filterable
              class="common-screen-input_100"
              v-model="ruleFormAdd.userDepartId"
              :options="selectDepart"
              @change="cascaderFun"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="选择角色" prop="roleId">
            <el-select clearable class="common-screen-input_100" v-model="ruleFormAdd.roleId" placeholder="请选择角色">
              <el-option v-for="item in selectRole" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="ruleFormAdd.roleId == 1" label-width="80px">
            <div class="serviceLevel">
              <el-form-item label="客服等级名称" label-width="120px">
                <el-select clearable class="common-screen-input_100" v-model="ruleFormAdd.serviceLevelId" placeholder="请选择客服等级">
                  <el-option v-for="item in levelSelect" :key="item.id" :label="item.serviceLevel" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="基础工资" label-width="120px">
                <el-input class="common-input-YUAN" :value="ruleFormAdd.serviceLevelId ? levelSelect.filter((item) => { return item.id == ruleFormAdd.serviceLevelId })[0].basePay : ''" placeholder="请选择客服等级" disabled></el-input>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="入职时间" prop="joinTime">
            <el-date-picker
              clearable
              class="common-screen-input_100"
              v-model="ruleFormAdd.joinTime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="员工账号/手机号" prop="account">
            <el-input
              class="common-screen-input_100"
              placeholder="请输入手机号"
              v-model="ruleFormAdd.account"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="!tableRow" label="登录密码" prop="passWord">
            <el-input
              class="common-screen-input_100"
              placeholder="请输入密码"
              v-model="ruleFormAdd.passWord"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item v-if="!tableRow" label="确认密码" prop="oldPassword">
            <el-input
              class="common-screen-input_100"
              placeholder="请确认密码"
              v-model="ruleFormAdd.oldPassword"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="员工头像" prop="oldPassword">
            <div v-if="ruleFormAdd.icon" class="icon-img">
              <img :src="ruleFormAdd.icon" alt="头像" >
            </div>
            <div class="upload-icon-container">
              <el-button class="common-screen-btn">选择图片</el-button>
              <input
                type="file"
                class="upload-icon-input"
                @input="chooseFile"
              />
            </div>
          </el-form-item>
        </el-form>
        <!-- <div style="text-align: center">
          <el-button
            class="common-screen-btn"
            type="primary"
            @click="() => submitForm('ruleFormUser')"
            >确 定</el-button
          >
          <el-button class="common-screen-btn" @click="handleClose()"
            >取 消</el-button
          >
        </div> -->
      </div>
    </a-modal>
  </div>
</template>
<script>
import { commonDepartAll } from "../../../service/common.js";
import { upload } from "../../../service/upload.js";
import { getUserInfo } from "../../../service/manage.js";
import { DateTransform, Config } from "../../../utils/index.js";
import { Modal } from "ant-design-vue";
import Vue from "vue";
Vue.use(Modal);
export default {
  comments: { aModal: Modal },
  props: ["drawer", "rolesCompany", "selectRole", "tableRow", 'levelSelect', 'companyId', 'selectDepart'],
  data() {
    let ruleOldPassWord = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请确认密码"));
      } else if (value !== this.ruleFormAdd.passWord) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      confirmLoading: false,
      ruleFormAdd: {
        // 新增员工数据
        account: "", // 账号（手机号）
        passWord: "",
        companyId: "",
        userDepartId: "",
        nickName: "",
        // note: "",
        roleId: "",
        joinTime: "",
        serviceLevelId: '',
      }, // form提交数据
      // selectDepart: [], //部门下拉
      rulesAdd: { // 校验规则
        nickName: [{ required: true, message: "请输入名称", trigger: "change" }],
        companyId: [{ required: true, message: "请选择公司", trigger: "change" }],
        roleId: [{ required: true, message: "请选择角色", trigger: "change" }],
        joinTime: [{ required: true, message: "请选择入职时间", trigger: "change" }],
        account: [{ required: true, message: "请输入员工账号/手机号码", trigger: "change"}],
        passWord: [{ required: true, message: "请输入登陆密码", trigger: "change" }],
        oldPassword: [{ validator: ruleOldPassWord, trigger: "change" }],
      },
    };
  },
  created() {
    // let tableRow = this.tableRow;
    // if (tableRow) {
    //   this.getUserInfo(tableRow);
    // }
  },
  methods: {
    async chooseFile(e) {
      // 上传头像
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
          e.target.value = "";
          return this.$message.error('文件大小不能超过50M')
      }
      if (!Config.fileType.imgType.includes(format)) {
          e.target.value = "";
          return this.$message.error(`只能上传 ${Config.fileMessage.imgType} 格式的文件`)
      }
      let ruleFormAdd = {...this.ruleFormAdd}
      let resData = (await upload({ file: file })).data;
      ruleFormAdd.icon = resData.filename
      this.ruleFormAdd = Object.assign({}, ruleFormAdd) ;
      e.target.value = "";
      this.$message.success("上传成功")
    },
    clearForm() {
      // 清空form
      this.ruleFormAdd = {
        account: "",
        passWord: "",
        companyId: "",
        userDepartId: "",
        nickName: "",
        // note: "",
        roleId: "",
        joinTime: "",
        icon: '',
        serviceLevelId: ''
      };
      console.log('---------------------')
      // this.selectDepart = [];
      if (this.$refs["ruleFormUser"]) this.$refs["ruleFormUser"].resetFields();
    },
    async getUserInfo(row) {
      // 获取详情
      let resData = (await getUserInfo({}, row.id)).data;
      this.commonDepartAll(resData.companyId);
      let params = {
        account: row.account,
        nickName: row.nickName,
        companyId: resData.companyId,
        userDepartId: resData.userDepartId,
        roleId: row.roleId,
        joinTime: new Date(row.joinTime),
        serviceLevelId: resData.serviceLevelId,
        icon: row.icon || resData.icon || ""
      };
      this.ruleFormAdd = Object.assign(this.ruleFormAdd, params);
      // this.params = resData;
    },
    cascaderFun() {
      // 选择部门
      let ruleFormAdd = { ...this.ruleFormAdd };
      if (!ruleFormAdd.companyId && ruleFormAdd.companyId!=0) {
        this.$message.error("请先选择所属公司");
        this.ruleFormAdd.userDepartId = [];
        // delete params.userDepartId;
        // this.params = params;
      }
    },
    companyFun(id) {
      //选择公司筛选
      this.ruleFormAdd.userDepartId = [];
      this.commonDepartAll(id);
    },
    async commonDepartAll(id) {
      // 部门 下拉
      let resData = (await commonDepartAll({}, id)).data;
      this.selectDepart = resData ? resData : [];
    },
    submitForm(formName) {
      // 新增/编辑用户确认
      let ruleFormAdd = { ...this.ruleFormAdd };
      if (ruleFormAdd.joinTime) {
        ruleFormAdd.joinTime = DateTransform(ruleFormAdd.joinTime);
      }
      if (this.tableRow) {
        delete ruleFormAdd.passWord;
        delete ruleFormAdd.oldPassword;
        delete this.rulesAdd.passWord;
        delete this.rulesAdd.oldPassword;
      } else {
        ruleFormAdd.companyId = this.companyId
        this.rulesAdd = Object.assign(this.rulesAdd, {
          passWord: [{ required: true, message: "请输入登陆密码", trigger: "change" }],
          oldPassword: [
            {
              validator: (rule, value, callback) => {
                if (value === "") {
                  callback(new Error("请确认密码"));
                } else if (value !== this.ruleFormAdd.passWord) {
                  callback(new Error("两次输入密码不一致!"));
                } else {
                  callback();
                }
              },
              trigger: "change",
            },
          ],
        });
      }
      if (typeof ruleFormAdd.userDepartId == "object") {
        ruleFormAdd.userDepartId = ruleFormAdd.userDepartId.join("");
      }
      if (!ruleFormAdd.serviceLevelId) {
        delete ruleFormAdd.serviceLevelId
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("addUserInfo", ruleFormAdd);
        } else {
          return false;
        }
      });
    },
    handleClose(done) {
      this.$emit("handleClose", done);
    },
  },
};
</script>
<style lang="less" scoped>
.userList-drawer {
  .serviceLevel {
    border-left: 4px solid #1890ff;
  }
  .icon-img {
    display: inline-block;
    margin: 0 10px;
    height: 40px;
    line-height: 40px;
    img {
      height: 40px;
    }
  }
  .upload-icon-input {
    width: 88px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  .drawer-container {
    padding: 0 44px 0 24px;
    text-align: left;
  }
  .upload-icon-container {
    display: inline-block;
    position: relative;
    width: 88px;
    height: 40px;
    .common-screen-btn {
      margin: 0;
    }
  }
  /deep/.el-drawer__open .el-drawer.rtl {
    width: 38% !important;
  }
}
</style>